import * as React from "react"

function SvgContact(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={512}
      height={512}
      {...props}
    >
      <path d="M26.9 21.314l-4.949-4.95a1 1 0 00-1.414 0l-2.122 2.121a3 3 0 01-4.243 0l-.242-.242a3 3 0 010-4.243l2.121-2.122a1 1 0 000-1.414L11.1 5.515a1 1 0 00-1.414 0L7.565 7.636a9.01 9.01 0 000 12.728l4.485 4.485a9.01 9.01 0 0012.728 0l2.122-2.121a1 1 0 000-1.414zm-5.656-2.829l3.535 3.536-.707.707-3.535-3.536zM10.394 7.636l3.535 3.535-.707.708-3.536-3.536zm3.07 15.8L8.979 18.95a7.012 7.012 0 01-.657-9.142l3.556 3.556a5.009 5.009 0 00.637 6.293l.242.242a5.012 5.012 0 006.293.637l3.556 3.556a7.013 7.013 0 01-9.142-.657z" />
      <path d="M27 32H5a3 3 0 01-3-3V5a3 3 0 013-3h22a3 3 0 013 3v24a3 3 0 01-3 3zM5 4a1 1 0 00-1 1v24a1 1 0 001 1h22a1 1 0 001-1V5a1 1 0 00-1-1zM27 62H5a3 3 0 01-3-3V37a3 3 0 013-3h22a3 3 0 013 3v22a3 3 0 01-3 3zM5 36a1 1 0 00-1 1v22a1 1 0 001 1h22a1 1 0 001-1V37a1 1 0 00-1-1z" />
      <path d="M25 41H7a1 1 0 00-1 1v12a1 1 0 001 1h18a1 1 0 001-1V42a1 1 0 00-1-1zm-9 7.65L9.632 43h12.734zm-3.734-.641L8 51.78v-7.555zm1.506 1.337l.9.8a1.993 1.993 0 002.671.005l.9-.8L22.361 53H9.639zm5.972-1.337L24 44.225v7.555zM59 2H37a3 3 0 00-3 3v24a3 3 0 003 3h5v12.538l-1.17-2.479a2.962 2.962 0 00-1.369-1.731 3.007 3.007 0 00-2.281-.3l-.921.248a2 2 0 00-1.378 2.56l4.174 12.124a.988.988 0 00.182.32l2.709 3.2.054 2.541A1 1 0 0043 62h14a1 1 0 001-1v-2.7l1.832-2.748A1.006 1.006 0 0060 55V42a2.926 2.926 0 00-.874-2.108 3.058 3.058 0 00-3.23-.682 2.867 2.867 0 00-.77-1.318 3.06 3.06 0 00-3.23-.682 2.867 2.867 0 00-.77-1.318A3.053 3.053 0 0048 35.171V32h11a3 3 0 003-3V5a3 3 0 00-3-3zM49 37a.974.974 0 01.712.306A.957.957 0 0150 38v7a1 1 0 002 0v-5a1 1 0 011-1 .974.974 0 01.712.306A.957.957 0 0154 40v5a1 1 0 002 0v-3a1 1 0 011-1 .974.974 0 01.712.306A.957.957 0 0158 42v12.7l-1.832 2.748A1.006 1.006 0 0056 58v2H43.979l-.041-1.921a1 1 0 00-.236-.626l-2.818-3.326L36.779 42.2l.914-.246a1.018 1.018 0 01.768.1.98.98 0 01.462.6c.015.059 3.173 6.763 3.173 6.763A1 1 0 0044 49V29.112A1.082 1.082 0 0144.907 28a.974.974 0 01.805.3.957.957 0 01.288.7v16a1 1 0 002 0v-7a1 1 0 011-1zm11-8a1 1 0 01-1 1H48v-1a2.926 2.926 0 00-.874-2.108 2.966 2.966 0 00-2.387-.881A3.077 3.077 0 0042 29.112V30h-5a1 1 0 01-1-1V5a1 1 0 011-1h22a1 1 0 011 1z" />
      <path d="M48 8a8 8 0 000 16h4a1 1 0 000-2h-4a6 6 0 116-6v1a1 1 0 01-2 0v-1a4.033 4.033 0 10-1.286 2.92A2.987 2.987 0 0056 17v-1a8.009 8.009 0 00-8-8zm0 10a2 2 0 112-2 2 2 0 01-2 2z" />
    </svg>
  )
}

export default SvgContact
