import React, { useState, useEffect } from "react"

import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import Image from "gatsby-image"

// Material UI

import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined"

import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

import Modal from "@material-ui/core/Modal"
import Popper from "@material-ui/core/Popper"
import Button from "@material-ui/core/Button"
import Fade from "@material-ui/core/Fade"
import Paper from "@material-ui/core/Paper"

import Menubar from "./Menubar"

import useUnmount from "../hooks/useUnMount"

import { useSelector, useDispatch } from "react-redux"

import {
  checkUserSession,
  signOutStart,
  toggleAccountModal,
} from "../../redux/User/user.actions"
import Account from "../Account"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    maxWidth: 600,
    width: "96%",
    // backgroundColor: theme.palette.background.paper,
    border: "1px solid #666",
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
}))
const mapState = ({ cartData, user }) => ({
  cartItems: cartData.cartItems,
  currentUser: user.currentUser,
  toggleAccount: user.toggleAccount,
})

const Header = () => {
  const [isMount, setIsMount] = useState(false)
  const classes = useStyles()
  // getModalStyle is not a pure function, we roll the style only on the first render
  // const [modalStyle] = React.useState(getModalStyle)
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
    setIsMount(false)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const toggleMenu = e => {
    document.body.style.overflow = !isMount ? "hidden" : null
    setIsMount(!isMount)
  }

  const isRender = useUnmount(isMount, 350)
  const { cartItems, currentUser, toggleAccount } = useSelector(mapState)
  const dispatch = useDispatch()
  let count = cartItems.reduce((a, b) => a + b.quantity, 0)
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      logo: file(absolutePath: { regex: "/suachuaonline24.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const logo = data?.logo?.childImageSharp?.fixed
  const handleSignOut = () => {
    dispatch(signOutStart())
    setOpen(false)
    navigate("/signin")
  }
  useEffect(() => {
    if (toggleAccount) {
      setOpen(true)
    }
    //  else {
    //   setOpen(false)
    // }
    // return () => {
    //   dispatch(toggleAccountModal())
    // }
  }, [toggleAccount])

  return (
    <header className="boxFull site-header">
      <div className="box boxFlex">
        <div
          htmlFor="burger"
          role="button"
          tabIndex={0}
          onClick={toggleMenu}
          onKeyDown={toggleMenu}
          className="header-burger-menu"
        >
          <div
            className={`${
              !isMount ? "button-menu" : "button-menu button-menu__active"
            }`}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className="header-logo">
          <Link to="/">
            <Image
              fixed={logo}
              alt="logo"
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
          </Link>
        </div>
        <div className="header-desktopNav">
          <ul className="header-navlink__items boxFlex">
            <li>
              <Link to="/" activeClassName="active-header">
                <span className="header-link__item">Home</span>
              </Link>
            </li>
            <li>
              <Link to="/about" activeClassName="active-header">
                <span className="header-link__item">About</span>
              </Link>
            </li>
            <li>
              <Link to="/services" activeClassName="active-header">
                <span className="header-link__item">Services</span>
              </Link>
            </li>

            <li>
              <Link to="/termin" activeClassName="active-header">
                <span className="header-link__item">Termin</span>
              </Link>
            </li>

            <li>
              <Link to="/shop" activeClassName="active-header">
                <span className="header-link__item">Shop</span>
              </Link>
            </li>
          </ul>
        </div>
        {isRender && <Menubar isMount={isMount} toggleMenu={toggleMenu} />}

        <div className="boxFlex">
          <Account
            open={open}
            currentUser={currentUser}
            handleClose={handleClose}
            handleOpen={handleOpen}
            handleSignOut={handleSignOut}
          />
          <div
            role="button"
            className="shop-cart"
            onClick={() => {
              navigate("/cart")
            }}
          >
            <ShoppingCartOutlinedIcon />
            <span className="count">{count}</span>{" "}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
