import Modal from "@material-ui/core/Modal"
import Paper from "@material-ui/core/Paper"
import Popover from "@material-ui/core/Popover"
import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography"
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded"
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined"
import RateReviewOutlinedIcon from "@material-ui/icons/RateReviewOutlined"
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined"
import VpnKeyIcon from "@material-ui/icons/VpnKey"
import CreateIcon from "@material-ui/icons/Create"
import { Link } from "gatsby"
import React, { useEffect } from "react"

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1010,
    "& .MuiPaper-root": {
      marginTop: 54,
      paddingTop: 10,
      boxShadow: "unset",
      // transform: "translateY(20px) !important",
      minWidth: 186,
      // paddingBottom: 24,
      "& .account-link": {
        display: "flex",
        paddingLeft: 16,
        fontSize: 16,
        lineHeight: "21px",

        "& svg": {
          fill: "#f05123",
          color: "#f05123",
        },
        "& a ": {
          color: "#676767",
        },
      },
    },
  },
  title: {
    textAlign: "center",
    paddingRight: 8,
    paddingLeft: 8,
    borderBottom: "1px solid #cecece",
    color: "#676767",
  },

  link: {
    display: " block",
    "& p": {
      padding: "8px",
    },
  },
  typography: {
    padding: "16px",
  },
}))

export default function Account({
  currentUser,
  handleClose,
  handleOpen,
  handleSignOut,
  open,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  // const [open, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState()
  const classes = useStyles()

  const handleClick = newPlacement => event => {
    setAnchorEl(event.currentTarget)
    handleOpen()
    // setOpen(prev => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }

  // useEffect(() => {
  //   if (open) {
  //     handleClose()
  //   }
  // }, [])
  return (
    <div className="just-a-modal">
      <Modal open={open} onClose={handleClick}>
        <Popover
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          onClose={() => {
            handleClose()
            // setAnchorEl(null)
          }}
          className={classes.root}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Typography className={classes.title} variant="h6">
            Kunden{" "}
          </Typography>
          {currentUser ? (
            <div>
              {/* <p className="user-displayname"> Hi {currentUser.displayName} </p> */}
              {/* <p className="user-email">{currentUser.email} </p> */}
              {/* <hr /> */}
              <p onClick={handleClose} className="account-link">
                {" "}
                <RateReviewOutlinedIcon />
                <Link to="/account">Mein Konto</Link>
              </p>
              <p onClick={handleClose} className="account-link">
                <ShoppingCartOutlinedIcon />
                <Link to="/cart">Warenkorb</Link>
              </p>
              <p
                onClick={handleClose}
                role="button"
                className="account-link"
                onClick={handleSignOut}
              >
                <ExitToAppOutlinedIcon />
                <Link to="/signin">Abmelden</Link>
              </p>
            </div>
          ) : (
            <>
              <p className="account-link">
                <VpnKeyIcon />
                <Link
                  onClick={handleClose}
                  className={classes.link}
                  to="/signin"
                >
                  Login
                </Link>
              </p>
              <p className="account-link">
                <CreateIcon />
                <Link
                  onClick={handleClose}
                  className={classes.link}
                  to="/signup"
                >
                  Register
                </Link>
              </p>
            </>
          )}
        </Popover>
      </Modal>

      <div className="header-link__account" onClick={handleClick("bottom-end")}>
        <span>
          <AccountCircleRoundedIcon />
        </span>
      </div>
    </div>
  )
}
