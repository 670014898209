const userTypes = {
  CHECK_USER_SESSION: "CHECK_USER_SESSION",

  SIGN_IN_EMAIL: "SIGN_IN_EMAIL",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",

  START_SIGN_UP: "START_SIGN_UP",
  SIGN_UP_ERROR: "SIGN_UP_ERROR",

  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGNOUT_SUCCESS",

  RESET_USER_ERROR: "RESET_USER_ERROR",

  TOGGLE_ACCOUNT_MODAL: "TOGGLE_ACCOUNT_MODAL",

  UPDATE_SHOPPING_ITEM: "UPDATE_SHOPPING_ITEM",
  UPDATE_SHOPPING_ITEM_STORE: "UPDATE_SHOPPING_ITEM_STORE",

  USER_LOADING_START: "USER_LOADING_START",
  USER_LOADING_DONE: "USER_LOADING_DONE",
}
export default userTypes
